import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { isAuthenticated } from '../../lib/Auth/utils';

// If not authenticated, redirects to auth page
const PrivateRoute = ({ component: Component, ...rest }) => {
  const [toAuthPage, setToAuthPage] = useState(null);

  useEffect(() => {
    const authWrapper = async () => {
      if (await isAuthenticated()) {
        setToAuthPage(false);
      } else {
        setToAuthPage(true);
      }
    };
    authWrapper();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        toAuthPage ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

// Defineds prop types for component
PrivateRoute.propTypes = {};
// Connects component with redux
export default PrivateRoute;
