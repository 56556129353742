import axios from 'axios';
import { url } from '../../consts';

// @TODO Make this function work
export const refreshTokenSetup = (res) => {
  // timing to renew access token
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
  console.log(refreshTiming);

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    console.log('newAuthRes:', newAuthRes);
    // saveUserToken(newAuthRes.access_token)
    console.log('new Auth Token', newAuthRes.id_token);
    // set up other timer after the first one
    setTimeout(refreshToken, refreshTiming);
  };

  // setup first refresh timer
  setTimeout(refreshToken, refreshTiming);
};

/**
 * returns user's google information
 * @param {function} - setter function for useState
 */
export const getUserInformation = async (setter) => {
  try {
    const res = await axios.get(`${url}/api/auth`, {
      headers: {
        'x-auth-token': localStorage.getItem('tokenId'),
      },
    });
    setter(res?.data);
  } catch (error) {
    console.log(error);
  }
};

/**
 * check is user is authenticated
 */
export const isAuthenticated = async () => {
  if (!localStorage.getItem('tokenId')) return false;

  try {
    await axios.get(`${url}/api/auth`, {
      headers: {
        'x-auth-token': localStorage.getItem('tokenId'),
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/**
 * check is user is authenticated
 * @param {object} res - google information
 */
export const createUser = async (googleData) => {
  try {
    const { profileObj } = googleData;
    const res = await axios({
      method: 'post',
      url: `${url}/api/users`,
      data: { ...profileObj, visitTimes: [Date.now()] },
    });
    console.log(res.data);
  } catch (error) {
    console.log(error);
  }
};

export const getUser = async () => {
  try {
    const resAuth = await axios.get(`${url}/api/auth`, {
      headers: {
        'x-auth-token': localStorage.getItem('tokenId'),
      },
    });
    const { data } = resAuth;
    console.log(data);
  } catch (error) {
    console.log(error);
  }
};

/**
 * check is user is authenticated
 * @param {object} res - google information
 */
export const addVisitTime = async () => {
  try {
    if (!sessionStorage.getItem('visitLogged')) {
      sessionStorage.setItem('visitLogged', true);
      // check if first visit
      // get google id
      let { data } = await axios.get(`${url}/api/auth`, {
        headers: {
          'x-auth-token': localStorage.getItem('tokenId'),
        },
      });
      const { userId } = data;
      // get user fromd db
      data = (await axios.get(`${url}/api/users/${userId}`)).data;
      const { visitTimes } = data;
      visitTimes.push(Date.now());
      // update user
      const res = await axios({
        method: 'put',
        url: `${url}/api/users/${userId}`,
        data: { visitTimes },
      });
      console.log(res.data);
    } else {
      console.log('Visit already logged');
    }
  } catch (error) {
    console.log(error);
  }
};
