export const url = 'https://go-global-server.herokuapp.com'; // prod
// export const url = 'http://localhost:3002'; // local
export const primaryColor = '#19313e';
export const secondaryColor = '#FF5157'; // ''#d12f1a';
export const lightColor = '#e9ebec';
export const altPrimaryColor = '#75b8d6';
export const landDotColor = [25, 49, 62]; // [117, 184, 214];
export const borderDotColor = [117, 184, 214]; //[209, 47, 26];
export enum TIME_MS {
  SEVEN_SECONDS = 7 * 1000,
}
