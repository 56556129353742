import React, { Suspense, lazy } from 'react';
import styles from './App.module.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from '../routes/auth/PrivateRoute';

const AuthPage = lazy(() => import('../routes/auth/AuthPage'));
const HomePage = lazy(() => import('../routes/home/HomePage'));
const EventPage = lazy(() => import('../routes/event/EventPage'));

/**
 * Render main app and do routing
 */
function App() {
  return (
    <Suspense fallback={<h1>Loading</h1>}>
      <Router>
        <div className={styles.container}>
          <Switch>
            <Route exact path={'/'} component={AuthPage} />
            <PrivateRoute exact path="/home" component={HomePage} />
            <PrivateRoute path="/event" component={EventPage} />
            <PrivateRoute
              exact
              path="/loading"
              component={() => <h1>Loading</h1>}
            />
            <Route component={() => <h1>Not Found</h1>} />
          </Switch>
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
